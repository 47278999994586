import { Body, Heading } from "@biom3/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { UnprotectedLayout } from "@/components";
import { usePassportProvider } from "@/context";
import { analytics, pageLegacyEvent } from "@analytics";

export default function Login() {
  const { t } = useTranslation();
  const { loginCallback, userInfo } = usePassportProvider();

  useEffect(() => {
    if (userInfo) {
      analytics.identify(userInfo?.sub, {
        name: userInfo?.nickname,
        email: userInfo?.email,
      });
    }
    pageLegacyEvent({ screen: "LoggedInRedirect", userJourney: "Profile" });
    loginCallback();
  }, [loginCallback, userInfo]);

  return (
    <UnprotectedLayout>
      <Heading>{t("login_welcome")}</Heading>
      <Body>{t("login_confirmed")}</Body>
    </UnprotectedLayout>
  );
}
